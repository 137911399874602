<script>
import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  name: "Login",
  data() {
    return {
      respuesta: "",
      form: {
        username: "",
        password: "",
      },
      error: false,
      showPassword: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
      const passwordInput = document.getElementById("password-input");
      passwordInput.type = this.showPassword ? "text" : "password";
      const toggleIcon = document.querySelector(".toggle-password i");
      toggleIcon.classList.toggle("la-eye");
      toggleIcon.classList.toggle("la-eye-slash");
    },
    async login() {
      await axios
        .post("https://smartcities.urad.es/auth/login", this.form)
        .then((response) => {
          if (response.data.success == true) {
            this.respuesta = response.data.redirect + "dashboard";
            location.href = this.respuesta;
            localStorage.setItem("username", this.form.username);
            localStorage.setItem("logo", response.data.logo);
           
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 900,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: "Signed in successfully",
            });
            this.$cookies.set("jwt", this.respuesta, "30m");
            this.$http.headers.common["Authorization"] =
              "Bearer " + this.respuesta;
          } else {
            this.error = true;
            if (this.error == true) {
              this.$swal.fire({
                icon: "error",
                title: "¡UPS! Algo salió mal",
                text: "Credenciales incorrectas",
              });
            }
          }
        });
    },
  },
};
</script>
<template>
  <!-- auth-page wrapper -->
  <div
    class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-50"
  >
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="auth-page-content">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="text-center mt-sm-5 mb-1 text-white-50">
                      <div>
                        
                      </div>
                      <div class="text-center mt-sm-5 mb-4">
                        <h1
                          style="
                            color: black;
                            -webkit-text-stroke: 2px white;
                            letter-spacing: 6px;
                            font-size: 80px;
                          "
                        >
                          uRAD
                        </h1>
                      </div>
                      <div class="text-center mt-sm-5 mb-4">
                        <h1
                          style="
                            color: black;
                            -webkit-text-stroke: 2px white;
                            letter-spacing: 2.3px;
                            font-size: 40px;
                          "
                        >
                          SMART CITY PLATFORM
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                  <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card mt-4">
                      <div class="card-body p-4">
                        <div class="p-2 mt-4">
                          <form @submit.prevent="submit">
                            <div class="mb-3">
                              <label for="username" class="form-label"
                                >Username</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                v-model="form.username"
                                id="username"
                                placeholder="Enter username"
                                required
                              />
                            </div>

                            <div class="mb-3">
                              <label class="form-label" for="password-input"
                                >Password</label
                              >
                              <div
                                class="position-relative auth-pass-inputgroup mb-3"
                              >
                                <input
                                  type="password"
                                  class="form-control pe-5 mb-3"
                                  v-model="form.password"
                                  placeholder="Enter password"
                                  id="password-input"
                                  required
                                />
                                 <input
                                  type="checkbox"
                                  class="btn btn-md btn-light toggle-password end-0 me-2"
                                  aria-label="Toggle password visibility"
                                  @click="togglePasswordVisibility"
                                />

                                <label class="form-label" for="password-input"
                                  >Show Password</label
                                >
                              </div>
                            </div>

                            <div class="mt-4">
                              <input
                                style="
                                  background-color: #49a6bf;
                                  color: #ffffff;
                                "
                                class="btn w-100"
                                type="submit"
                                @click="login"
                                value="Login"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                </div>
                <!-- end row -->
              </div>
              <!-- end container -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <h5 class="mb-0 text-white">
                &copy; {{ new Date().getFullYear() }} uRAD. Designed by Anteral
              </h5>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
